<template>
  <div>
    <div
      class="flex justify-between  text-oWhite rounded-md  cursor-pointer"
      :class="getActivityStatusClassVariant"
      @click="openModal()"
    >
      <div class="flex justify-center items-center px-3 py-1 ">
        {{ getActivityStatusTitle }}
      </div>
      <div class="flex justify-center items-center border-l w-7 p-0">
        <i class="fas fa-chevron-down"></i>
      </div>
    </div>
    <t-modal
      :name="`buyer-activity-status-${id}`"
      :variant="`confirmation`"
      :hideCloseButton="true"
      :disableBodyScroll="true"
      :escToClose="!isLoading"
      :clickToClose="!isLoading"
      ref="popup"
    >
      <div>
        <section class="px-4 text-2xl font-extrabold text-center mt-8">
          {{
            $t('components.marketPlaceManagement.orders.details.marketplace.steps.buyers.modal.headline')
          }}
        </section>
        <section
          class="px-4 py-5"
          :class="form.user_status === 'A' ? 'mb-20' : ''"
        >
          <AppInput
            v-model="form.user_status"
            type="richselect"
            :name="$t('components.marketPlaceManagement.orders.details.marketplace.steps.buyers.modal.title.status')"
            :label=" $t('components.marketPlaceManagement.orders.details.marketplace.steps.buyers.modal.title.status')"
            rules="required"
            :placeholder=" $t('components.marketPlaceManagement.orders.details.marketplace.steps.buyers.modal.placeHolder.status')"
            value-attribute="value"
            text-attribute="text"
            :options="activityOptions"
          />
        </section>
        <section class="px-4 " v-if="form.user_status === 'A' ? false : true">
          <AppInput
            v-model="form.status_reason"
            type="richselect"
            name="reasons"
            :label="$t('components.marketPlaceManagement.orders.details.marketplace.steps.buyers.modal.title.reasons')"
            rules="required"
            :placeholder="$t('components.marketPlaceManagement.orders.details.marketplace.steps.buyers.modal.placeHolder.reasons')"
            value-attribute="value"
            text-attribute="text"
            :options="
              form.user_status === 'H' ? reasons.hold : reasons.deactivate
            "
          />
        </section>
        <section
          class="px-4 py-5"
          v-if="form.user_status === 'A' ? false : true"
        >
          <AppInput
            v-model="form.status_remark"
            type="textarea"
            name="remarks"
            :label="$t('components.marketPlaceManagement.orders.details.marketplace.steps.buyers.modal.title.remarks')"
            rules=""
            placeholder=""
          />
        </section>
        <section
          class="flex items-center justify-end px-4 py-4 mt-6 space-x-5 bg-gray-50"
        >
          <AppButton variant="secondary" :class="`w-1/2`" @click="closeModal()">
           {{
            $t('components.stepNavigation.cancel')
          }}
          </AppButton>

          <AppButton
            :class="`w-1/2`"
            :isLoading="isLoading"
            @click="assignStatus()"
          >
            {{
            $t('components.marketPlaceManagement.orders.details.marketplace.steps.buyers.modal.btnText.assign')
          }}
          </AppButton>
        </section>
      </div>
    </t-modal>
  </div>
</template>

<script>
import { useEndpoints } from '@/composables'
// import xMan from '@/utils/xMan'
import _ from 'lodash'
export default {
  name: 'VehicleAssignToBuyerAction',
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isLoading: false,
    activityOptions: [
      {
        value: 'A',
        text: 'Activate',
      },
      {
        value: 'H',
        text: 'Hold',
      },
      {
        value: 'D',
        text: 'Deactivate',
      },
    ],
    reasons: {
      deactivate: [
        {
          text: 'Damaged Vehicle',
          value: 'DAMAGED_VEHICLE',
        },
        {
          text: 'Violates Geofence Rules',
          value: 'GEOFENCE_RULE_VIOLATION',
        },
        {
          text: 'Payment Compliance',
          value: 'PAYMENT_COMPLIANCE',
        },
      ],
      hold: [
        {
          text: 'Suspicious Activity - OTP',
          value: 'SUSPICIOUS_ACTIVITY_OTP',
        },
        {
          text: 'Suspicious Activity - Vehicle',
          value: 'SUSPICIOUS_ACTIVITY_VEHICLE',
        },
        {
          text: 'Suspicious Activity - Transactions',
          value: 'SUSPICIOUS_ACTIVITY_TRANSACTIONS',
        },
        {
          text: 'Suspicious Activity - Trips',
          value: 'SUSPICIOUS_ACTIVITY_TRIPS',
        },
      ],
    },
    form: {
      user_status: null,
      status_reason: null,
      status_remark: null,
    },
  }),
  // watch: {
  //   data: {
  //     immediate: true,
  //     deep: false,
  //     handler(val) {
  //       this.form.user_status = val.account_status
  //     },
  //   },
  // },
  computed: {
    getActivityStatusTitle() {
      if (this.data.account_status === 'A') {
        return 'Active'
      } else if (this.data.account_status === 'H') {
        return 'Hold'
      } else {
        return 'Deactive'
      }
    },
    getActivityStatusClassVariant() {
      if (this.data.account_status === 'A') {
        return 'bg-green-500'
      } else if (this.data.account_status === 'H') {
        return 'bg-orange-500'
      } else {
        return 'bg-gray-400'
      }
    },
  },
  methods: {
    openModal() {
      this.form.user_status = null
      this.form.status_reason = null
      this.form.status_remark = null
      this.$modal.show(`buyer-activity-status-${this.id}`)
    },
    closeModal() {
      this.form.user_status = null
      this.form.status_reason = null
      this.form.status_remark = null
      this.$modal.hide(`buyer-activity-status-${this.id}`)
    },

    async assignStatus() {
      this.isLoading = true
      const url = useEndpoints.user.org.updateStatus(this.data.id)
      const formDataProxy = { ...this.form }
      if (formDataProxy.user_status === 'A') {
        delete formDataProxy.status_reason
        delete formDataProxy.status_remark
      } else {
        Object.keys(this.form).map((key) => {
          if (_.isEmpty(formDataProxy[key])) {
            delete formDataProxy[key]
          }
        })
      }

      const data = new FormData()
      for (const key in formDataProxy) {
        data.append(key, formDataProxy[key])
      }

      await this.$http
        .patch(url, data)
        .then(() => {
          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: 'Successful',
              text: 'Status update successfully',
            },
            3000
          )
          this.closeModal()
          this.$emit('force-sync')
        })
        .catch((err) => {
          console.log('unassigned-err', { err })
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: 'Error occurred!',
              text: 'Failed to update status',
            },
            7000
          )
        })
        .finally(() => (this.isLoading = false))

      // console.log('formDataProxy', formDataProxy)
      // if (this.form.owner === 'none') {
      //   const url = `${useEndpoints.vehicle.buyer.assignOwner(
      //     this.data.id
      //   )}?unassigned=true`

      //   await this.$http
      //     .patch(url)
      //     .then(() => {
      //       this.$notify(
      //         {
      //           group: 'generic',
      //           type: 'success',
      //           title: 'Successful',
      //           text: 'Unassigned Successfully',
      //         },
      //         3000
      //       )
      //       this.closeModal()
      //       this.$emit('force-sync')
      //     })
      //     .catch((err) => {
      //       console.log('unassigned-err', { err })
      //       this.$notify(
      //         {
      //           group: 'generic',
      //           type: 'error',
      //           title: 'Error occurred!',
      //           text: 'Failed to unassign',
      //         },
      //         7000
      //       )
      //     })
      //     .finally(() => (this.isLoading = false))
      // } else {
      //   const url = useEndpoints.vehicle.buyer.assignOwner(this.data.id)
      //   const formData = new xMan().toFormData()
      //   formData.append('owner', this.form.owner)

      //   await this.$http
      //     .patch(url, formData)
      //     .then(() => {
      //       this.$notify(
      //         {
      //           group: 'generic',
      //           type: 'success',
      //           title: 'Successful',
      //           text: 'Assigned Successfully',
      //         },
      //         3000
      //       )
      //       this.closeModal()
      //       this.$emit('force-sync')
      //     })
      //     .catch((err) => {
      //       console.log('assigned-err', { err })
      //       this.$notify(
      //         {
      //           group: 'generic',
      //           type: 'error',
      //           title: 'Error occurred!',
      //           text: 'Failed to assign',
      //         },
      //         7000
      //       )
      //     })
      //     .finally(() => (this.isLoading = false))
      // }
    },
  },
}
</script>

<style lang="scss" scoped></style>
